// Fonte
$font-family-Sora: 'Sora', sans-serif;

// Backgroud .app
$color-background-app: radial-gradient(#4b5862, #000);

// Backgroud.app-header
$color-background-app-header: rgb(24, 28, 32);

// Card
$color-background-card: rgba(255, 255, 255, 0.03);
$color-box-shadow-card: 0 0 10px rgba(0, 0, 0, 0.25);

// Backgroud página Redefinir senha
$color-background-page: linear-gradient(114.73deg, #333f48 43.22%, #3d454b 80.12%);

// Background botões de paginação
$color-button-pagination: rgba(255, 255, 255, 0.03);

// Hover
$color-hover-button-submit: 0 0 7px rgba(191, 245, 2, 0.8), 0 0 6px rgba(191, 245, 2, 0.6);
$color-hover-button-back: 0 0 7px rgba(161, 181, 194, 0.8), 0 0 6px rgba(161, 181, 194, 0.6);
$color-hover-button-edit: 0 0 7px rgba(54, 153, 255, 0.8), 0 0 6px rgba(54, 153, 255, 0.6);
$color-hover-button-delete: 0 0 7px rgba(246, 78, 96, 0.8), 0 0 6px rgba(246, 78, 96, 0.6);
$color-hover-button-secondary: 0 0 7px rgba(255, 255, 255, 0.8), 0 0 1px rgba(255, 255, 255, 0.8);

// Cores
$lime-green: #cff532;
$error: #e94c4c;
$carmine-red: #f64e60;
$dodger-blue: #3699ff;
$dark-gunmetal: #23282c;
$dark-slate-gray: #333f48;
$gunmetal: #4b5862;
$slate-gray: #677985;
$slate-blue: #73818f;
$cadet-blue: #a1b5c2;
$grayishBlue: #868c91;
$white: #ffffff;
