@mixin reactSelect {
  // Container
  .css-vj8t7z {
    background-color: transparent;
    border: 1px solid $slate-gray;
    border-radius: 30px;
    min-height: 40px;
    font-family: $font-family-Sora;
    color: $cadet-blue;
    position: relative;
    outline: none;
  }

  // Container focus
  .css-2o5izw {
    background-color: $color-background-app-header !important;
    border-radius: 30px;
    border: none;
    box-shadow: none;
  }

  // Container span selected
  .css-xwjg1b {
    border-radius: 30px;
    background: #23282c;
    border: 1px solid #00458b;
  }

  // Span selected
  .css-12jo7m5 {
    color: white;
    font-family: $font-family-Sora;
  }

  // Item selected
  .css-1492t68 {
    color: $cadet-blue;
  }

  // Menu open
  .css-15k3avv {
    background-color: $color-background-app-header !important;
    border-color: $slate-gray;
    color: $cadet-blue;
    font-family: $font-family-Sora;
    transition: box-shadow 0.2s ease;
  }

  // Item menu
  .css-wqgs6e {
    background-color: transparent;

    &:hover {
      background-color: #73818f;
      color: white;
    }
  }

  // Separator
  .css-d8oujb {
    display: none;
  }

  .css-1g6gooi,
  .css-xp4uvy {
    color: $cadet-blue;
  }

  // .Select-control {
  //   background-color: transparent;
  //   border: 1px solid $slate-gray;
  //   border-radius: 30px;
  //   height: 40px;
  //   font-family: $font-family-Sora;
  //   color: $cadet-blue;
  //   position: relative;
  // }

  // .Select-placeholder {
  //   color: $cadet-blue;
  //   padding: 0.1rem 1.5rem;
  //   font-size: 14px;
  // }

  // .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
  // .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label,
  // .Select-value-label {
  //   color: $cadet-blue;
  // }

  // .Select.is-open div {
  //   background-color: $color-background-app-header !important;
  //   border-color: $slate-gray;
  //   color: $cadet-blue;
  //   font-family: $font-family-Sora;
  //   transition: box-shadow 0.2s ease;

  //   &:hover {
  //     color: $white;
  //     background-color: $slate-blue !important;
  //   }
  // }

  // .Select.is-focused:not(.is-open) > .Select-control {
  //   background-color: transparent;
  // }
}

@mixin input-style {
  background-color: transparent;
  border: 1px solid $slate-gray;
  outline: none !important;
  height: 40px;
  border-radius: 30px;
  padding: 0 1rem;

  font-family: $font-family-Sora;
  font-size: 14px;
  color: $cadet-blue;

  &:focus {
    box-shadow: none;
    background-color: transparent;
    border-color: $white;
    color: $cadet-blue;
  }

  &::placeholder {
    color: $cadet-blue;
  }

  &:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, 0.3);
  }

  option {
    background-color: $color-background-app-header;
    color: $cadet-blue;
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='lightgray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 99.09%;
  background-position-y: 8px;

  &[type='date'] {
    background-image: none;
    padding-right: 0.5rem;

    &::-webkit-calendar-picker-indicator {
      width: 16px;
      height: 20px;
      color: white;
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="white" fill-opacity=".54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E');
      background-repeat: no-repeat;
      cursor: pointer;
      padding-top: 0.5rem;
    }
  }

  &[type='text'],
  &[type='password'] {
    background-image: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: $cadet-blue;
    caret-color: $white;
  }
}
