// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';

@import './styles/variables.scss';
@import './styles/mixins.scss';

body {
  background-color: $dark-gunmetal;
}

// Modal
.modal-content {
  background-color: $dark-gunmetal;
  border: none;

  @include reactSelect;

  .modal-body {
    padding: 0;
  }

  .card-body {
    padding: 1.25rem 1.25rem 0 1.25rem;
  }
}

.modal-content .Select.is-open div {
  background-color: transparent;

  input {
    color: #a1b5c2;
  }

  &:hover {
    background-color: transparent;
  }
}

.Select-control .Select-value {
  background-color: $dark-gunmetal;
}

#root {
  .app {
    background: $color-background-app;
    @include reactSelect;

    // ReactSelect app
    .Select.is-open div {
      background-color: transparent;

      input {
        color: #a1b5c2;
      }

      &:hover {
        background-color: transparent;
      }
    }

    .Select-control .Select-value {
      background-color: $dark-gunmetal;
    }
    // End ReactSelect app

    .app-body {
      overflow-x: visible;
    }

    // Dropdown Logout
    .app-header {
      background: $color-background-app-header;

      .dropdown-menu {
        background: $color-background-app-header;

        .dropdown-item,
        i {
          color: $white;
          margin-right: 5px;
        }

        .dropdown-item {
          font-family: $font-family-Sora;
          transition: box-shadow 0.2s ease;

          &:hover {
            background: transparent;
            box-shadow: $color-hover-button-secondary;
          }
        }
      }

      .nav-item .nav-link > .img-avatar {
        transition: box-shadow 0.2s ease;
        &:hover {
          background: transparent;
          box-shadow: $color-hover-button-secondary;
        }
      }
    }

    // Menu ativo
    .sidebar .nav-link.active .nav-icon {
      color: $lime-green;
    }

    // Hover Menu
    .sidebar .nav-link {
      transition: hover 0.2s ease;

      &:hover {
        background: $lime-green;
        font-weight: 600;
        color: $dark-slate-gray;

        &::before {
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%2373818f' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
        }

        .nav-icon {
          color: $dark-slate-gray;
        }
      }
    }

    .breadcrumb,
    .sidebar,
    .app-footer {
      font-family: $font-family-Sora;
      font-size: 14px;
    }

    .app-footer,
    .breadcrumb {
      background-color: transparent;
    }

    .app-header,
    .breadcrumb {
      border-bottom: 1px solid $slate-gray;
    }

    .app-footer {
      border-top: 1px solid $slate-gray;

      span {
        a {
          color: $lime-green;
        }

        .spanFooter {
          color: $slate-blue;
        }
      }
    }

    .breadcrumb {
      a {
        color: $lime-green;
      }
    }

    //Pagination Table
    .page-item.disabled {
      cursor: not-allowed;
    }

    .page-item.disabled .page-link {
      background: transparent;
      border-color: unset;
    }

    .page-item .page-link {
      background: $color-button-pagination;
      backdrop-filter: blur(10px) !important;
      border: 1px solid $slate-gray;
      color: $cadet-blue;
      transition: box-shadow 0.2s ease;

      &:hover {
        background: transparent;
        box-shadow: $color-hover-button-secondary;
        color: $white;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .page-item.active .page-link {
      border: 1px solid $white;
      background: transparent;
      box-shadow: none;
    }

    .row {
      .dropdown {
        > div {
          background: $color-background-app-header;

          a {
            color: $cadet-blue;
            transition: box-shadow 0.2s ease;

            &:hover {
              background: transparent;
              box-shadow: $color-hover-button-secondary;
            }
          }
        }

        .btn-secondary {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 35px;
          background: transparent;
          color: $white;
          transition: box-shadow 0.2s ease;

          &:focus {
            box-shadow: none;
          }

          &:hover {
            background: transparent;
            box-shadow: $color-hover-button-secondary;
          }
        }
      }
    }
    //End pagination Table
  }

  .react-bs-container-header.table-header-wrapper {
    border-radius: 0;
  }
}

@media (max-width: 992px) {
  .app-header {
    > div {
      background-color: transparent !important;
    }
  }
}
